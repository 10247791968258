import React, { useEffect } from 'react'
import { useEmployerContext } from '../../contexts/Employer/employerProvider'

interface EmployerIdentificationLayerType {
  children: React.ReactChildren | React.ReactNode | React.ReactElement
}

// The purpose of this is to ensure that an employer slug is present before rendering the children components.
// If there is no slug, the user will be redirected out to the individual page where they can search for their employer.
const EmployerIdentificationLayer = ({
  children
}: EmployerIdentificationLayerType):
  | React.ReactChildren
  | React.ReactNode
  | React.ReactElement
  | null => {
  const { initialized, slug } = useEmployerContext()

  useEffect(() => {
    if (initialized && !slug) {
      window.location.href = 'https://www.goodpath.com/individuals'
    }
  }, [initialized, slug])

  return <>{children}</>
}

export default EmployerIdentificationLayer
