import { Link, graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { useLocalizedContent } from '../../utils/localization/useLocalizedContent'

export default function LoginPrompt() {
  const data = useStaticQuery(query)
  const { translations } = useLocalizedContent<any, Translations>(data)

  return (
    <p className="leading-tight text-md">
      {translations.alreadyHaveAccount}&nbsp;
      <Link to="/login">{translations.loginButton}</Link>
    </p>
  )
}

interface Translations {
  loginButton: string
  alreadyHaveAccount: string
}

const query = graphql`
  query LoginPromptQuery {
    allContentfulContentGroup(
      filter: { readableId: { eq: "signup-login" } }
    ) {
      nodes {
        ...ContentGroupFragment
      }
    }
  }
`
