import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { Form } from 'react-final-form'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import useLocalStorage from '../utils/useLocalStorage'
import ConditionSelector from '../components/common/ConditionSelector'
import Button from '../components/common/Button'
import { conditionValidator } from '../validation/registerValidator'
import { useUserContext } from '../contexts/User/userContext'
import { NoIndex } from '../components/NoIndex'
import { useEmployerContext } from '../contexts/Employer/employerProvider'
import { currentActiveQuizKey } from '../constants/localStorage'
import EmployerIdentificationLayer from '../components/LayerComponents/EmployerIdentificationLayer'
import { useLocalizedContent } from '../utils/localization/useLocalizedContent'
import LoginPrompt from '../components/common/LoginPrompt'
import { StringParam, useQueryParam } from 'use-query-params'

const translationsKeys = {
  header: 'header',
  nextButton: 'nextButton'
}

const SelectCondition = () => {
  const data = useStaticQuery(query)
  const { translations } = useLocalizedContent(data)

  const { employer } = useEmployerContext()
  const eligibleConditions = useMemo(
    () => employer?.eligibleConditions?.map((c) => c.slug) || [],
    [employer?.eligibleConditions]
  )

  const [conditionParam, setConditionParam] = useQueryParam(
    'condition',
    StringParam
  )

  const { user, setUserCondition } = useUserContext()
  const [currentActiveQuiz, setCurrentActiveQuiz] = useLocalStorage(
    currentActiveQuizKey
  )

  const [formValue, setFormValue] = useState({
    condition: ''
  })

  const [selectedCondition, setSelectedCondition] = useState(currentActiveQuiz)

  useEffect(() => {
    if (!selectedCondition && conditionParam) {
      setSelectedCondition(conditionParam)
    } else if (!selectedCondition && eligibleConditions.length > 0) {
      setSelectedCondition(eligibleConditions[0])
    }
  }, [
    conditionParam,
    eligibleConditions,
    selectedCondition,
    setSelectedCondition
  ])

  useEffect(() => {
    setFormValue({ condition: selectedCondition })
    setConditionParam(selectedCondition)
  }, [selectedCondition, setConditionParam, setFormValue])

  const onSubmit = useCallback(
    async (values) => {
      const { condition } = values
      setCurrentActiveQuiz(condition)

      if (user.isAuth) {
        setUserCondition(condition)
      }
      return user.isAuth
        ? navigate(`/quiz/${condition}`)
        : navigate(employer?.slug ? `/signup?e=${employer?.slug}` : '/signup')
    },
    [user.isAuth, employer?.slug, setCurrentActiveQuiz, setUserCondition]
  )

  return (
    <EmployerIdentificationLayer>
      <NoIndex />
      <div className="flex flex-col items-center justify-center px-6 bg-white">
        <div className="pt-3 text-lg leading-snug text-center text-gp-grey-100 md:text-xl md:pt-8 md:pb-4">
          <h1 className="mb-4 text-3xl text-center">
            {translations[translationsKeys.header] ||
              'Select your primary concern'}
          </h1>
        </div>

        <div className="flex flex-col justify-center pt-6 pb-8 mb-4 md:px-6">
          <Form
            onSubmit={onSubmit}
            validate={conditionValidator}
            initialValues={formValue}
            render={({ handleSubmit, submitting }) => (
              <form className="w-full" onSubmit={handleSubmit}>
                <ConditionSelector
                  value={selectedCondition}
                  onChange={setSelectedCondition}
                  eligibleConditions={eligibleConditions}
                />
                <div className="flex items-center justify-center pt-6">
                  <Button
                    id="submit-button"
                    type="submit"
                    isLoading={submitting}
                    disabled={submitting}
                  >
                    {translations[translationsKeys.nextButton] || 'Next'}
                  </Button>
                </div>
              </form>
            )}
          />
          {!user?.isAuth && (
            <div className="items-center justify-center mt-6 text-center">
              <LoginPrompt />
            </div>
          )}
        </div>
      </div>
    </EmployerIdentificationLayer>
  )
}

const query = graphql`
  query SelectConditionQuery {
    allContentfulContentGroup(
      filter: { readableId: { eq: "select-condition" } }
    ) {
      nodes {
        ...ContentGroupFragment
      }
    }
  }
`

export default SelectCondition
