import React, { useState, useEffect } from 'react'
import { Field } from 'react-final-form'
import ConditionProvider, { useConditionContext } from './ConditionProvider'

const Radio = ({ id, checked, imgSrc, altImage, onClick, title, content }) => {
  return (
    <div className="relative flex">
      <label htmlFor={id} className="flex flex-col ml-6 cursor-pointer">
        <div className="flex items-center mb-3 space-x-6">
          <div>
            <Field
              id={id}
              type="radio"
              name="condition"
              checked={checked}
              value={id}
              component="input"
              className="w-5 h-5 cursor-pointer form-radio"
              onClick={onClick.bind(null, id)}
            />
          </div>
          <img src={imgSrc} alt={altImage} className="w-16 h-16" />
          <div className="flex flex-col">
            <span className="block text-xl leading-snug md:text-2xl text-gp-grey-100 md:mb-1">
              {title}
            </span>
            <span className="hidden text-base leading-snug md:block text-gp-grey-80">
              {content}
            </span>
          </div>
        </div>
        <span className="block text-base leading-snug md:hidden text-gp-grey-80">
          {content}
        </span>
        <div />
      </label>
    </div>
  )
}

const ConditionSelectorComponent = ({
  value,
  onChange,
  eligibleConditions,
}) => {
  const [selectedValue, setSelectedValue] = useState(value)
  const { conditions } = useConditionContext()

  useEffect(() => {
    setSelectedValue(value)
  }
    , [value])


  useEffect(() => {
    onChange(selectedValue)
  }, [onChange, selectedValue])

  return (
    <>
      <fieldset className="mb-6">
        <legend className="sr-only">Select a Condition</legend>
        <div className="space-y-8 bg-white">
          {conditions
            .filter(
              (c) => eligibleConditions.includes(c.slug)
            )
            .map((opt) => {
              return (
                <Radio
                  key={opt.slug}
                  id={opt.slug}
                  checked={selectedValue === opt.slug}
                  onClick={setSelectedValue}
                  title={opt.fullTitle}
                  altImage={opt.fullTitle}
                  imgSrc={opt.icon1?.file?.url}
                  content={opt.subtitle}
                />
              )
            })}
        </div>
      </fieldset>
    </>
  )
}

const ConditionSelector = (props) => {
  return (
    <ConditionProvider>
      <ConditionSelectorComponent {...props} />
    </ConditionProvider>
  )
}

export default ConditionSelector
