import IsEmail from 'validator/lib/isEmail'
import { passwordValidators } from './loginValidator'
import validator from './validator'

const translationKeys = {
  divisionRequiredError: 'divisionRequiredError',
  firstNameError: 'firstNameError',
  lastNameError: 'lastNameError',
  emailError: 'emailError',
  emailRequiredError: 'emailRequiredError',
  conditionRequiredError: 'conditionRequiredError',
}

export const conditionValidation = (translations) => [
  {
    message: translations[translationKeys.conditionRequiredError] || 'Please select a condition.',
    validate: (value) => {
      return value
    }
  }
]


export const emailValidators = (translations) => [
  {
    message: translations[translationKeys.emailRequiredError] || 'Email is required',
    validate: (value) => {
      return value && value.trim().length !== 0
    }
  },
  {
    message: translations[translationKeys.emailError] || 'Provide a valid email',
    validate: (value) => {
      return IsEmail(value)
    }
  }
]

export const firstNameValidation = (translations) => [
  {
    message: translations[translationKeys.firstNameError] || 'Please enter a valid First Name.',
    validate: (_value) => {
      const value = _value?.trim()
      return value && value.length > 0 && value.length <= 35
    }
  }
]

export const lastNameValidation = (translations) => [
  {
    message: translations[translationKeys.lastNameError] || 'Please enter a valid Last Name.',
    validate: (_value) => {
      const value = _value?.trim()
      return value && value.length > 0 && value.length <= 35
    }
  }
]

export const divisionDropdownValidation = (translations) => [
  {
    message:
      translations[translationKeys.divisionRequiredError] || 'A company/division is required. If you are not sure, select, "I\'m not sure".',
    validate: (value) => {
      return (
        value &&
        value.length > 0 &&
        value !== 'Please select your company/division'
      )
    }
  }
]

const enrollmentTypeValidation = (translations) => [
  {
    message: translations[translationKeys.key] || 'Required',
    validate: (value) => {
      return value
    }
  }
]

const baseRegisterErrorMessages = (translations) => {
  return {
    condition: conditionValidation(translations),
    email: emailValidators(translations),
    firstName: firstNameValidation(translations),
    lastName: lastNameValidation(translations),
    password: passwordValidators(translations)
  }
}

const employerRegisterErrorMessages = (translations = {}) => {
  return {
    ...baseRegisterErrorMessages(translations),
    ...{
      workEmail: emailValidators(translations)
    }
  }
}

const conditionErrorMessage = (translations = {}) => {
  return {
    condition: conditionValidation(translations)
  }
}

const registerValidator = (translations = {}) => (values) => {
  return validator(baseRegisterErrorMessages(translations), values)
}

export const employerRegisterValidator = (translations = {}) => (values) => {
  return validator(employerRegisterErrorMessages(translations), values)
}

export const conditionValidator = (values) => {
  return validator(conditionErrorMessage, values)
}

export const employerRegisterValidatorWithDivisions = (translations = {}) => (values) => {
  return validator(
    {
      ...baseRegisterErrorMessages(translations),
      ...{
        division: divisionDropdownValidation(translations)
      }
    },
    values
  )
}

export const employerRegisterValidatorWithDivisionsAndEnrollmentType = (translations = {}) => (
  values
) => {
  return validator(
    {
      ...baseRegisterErrorMessages(translations),
      ...{
        division: divisionDropdownValidation(translations),
        enrollmentType: enrollmentTypeValidation(translations)
      }
    },
    values
  )
}

export default registerValidator
